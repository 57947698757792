<template>
<div class='img-explore-page'>
     <el-dialog :visible.sync="imgVisible" width="800px" class="dialong-style" center>
      <div class="img-explore-content">
         <div style="max-height:400px;overflow-y:auto">
             <img src="@/assets/img/common/exploreImg.png" alt="">
         </div>
         <div style="max-height:400px;width:320px">
             <p class="img-explore-text">示意图</p>
             <p class="img-explore-title">此二维码，将在此商圈首页广告图进行展示</p>
             <el-button type="primary" size="default" @click="sure" style="margin:200px 40px">已知晓</el-button>
             
         </div>
      </div>
    </el-dialog>
</div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      imgVisible: false,
    };
  },
  computed: {},
  watch: {},
  created() {

  },
  mounted() {

  },
  // 方法集合
  methods: {
    sure() {
      this.imgVisible = false;
    },
  },
};
</script>
<style lang='scss' >
.img-explore-content{
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    .img-explore-text{
        font-weight: 800;
        margin:10px 40px;
        font-size: 24px;
    }
    .img-explore-title{
        font-weight: 400;
        margin:20px 40px;
        font-size: 18px;
    }
}
</style>

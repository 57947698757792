import api from './api';
// 商圈分类组件总组件
export const $sg_getMchGroupCatSelect = (params) => api.get('/j/agent/mchGroupCat/getCatList', params);
// 商圈已选组件 /j/agent/mchGroupCat/getAgentCat
export const $sg_mchGroupCatAgentSelect = (params) => api.get('/j/agent/mchGroupCat/getAgentCat', params);
// 团购订单 /j/agent/mchActiveGroupOrder/getOrderList
export const $sg_getGroupOrderList = (params) => api.get('/j/agent/mchActiveGroupOrder/getOrderList', params);
// 团购订单总量统计 /j/agent/mchActiveGroupOrder/getGroupBuyAmount
export const $sg_getGroupBuyAmount = (params) => api.get('/j/agent/mchActiveGroupOrder/getGroupBuyAmount', params);
// 保存已选组件 /j/agent/mchGroupCat/addGroupCat
export const $sp_addEditGroupCat = (params) => api.post('/j/agent/mchGroupCat/addGroupCat', params);

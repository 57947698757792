<template>
  <div class='shop-group-distri'>
    <ul class="shop-group-distri-content">
      <!-- 选择组件 -->
      <li class="shop-group-distri-layout li" v-if="selectStatus">
        <p><span class="distri-layout-mark"></span><span class="distri-layout-title">分类组件</span><span
            class="distri-layout-tip">最多可选择20个组件，用于团购商品的分类</span></p>
        <p class="split-line"></p>

        <div class="shop-group-distri-select">
          <p class="distri-select-title">选择组件</p>
          <ul class="distri-select-all">
            <li class="distri-select-item" v-for="(item,index) in groupAllList" :key='index'>
              <div @click="selectType(item,index)">
                <img :src="item.icon" alt="" style="width:80px;">
                <img :src="item.selected?SelectIcon:noSelectIcon" alt="" class="selcet-icon">
                <p>{{item.name}}</p>
              </div>
            </li>
          </ul>
          <footer>
            <el-badge :value="selectItemList.length" :max="20" class="item">
              <img src="@/assets/img/common/selectNumber.png" alt=""  @click="openDialog">
            </el-badge>
            <span style="font-size:16px" @click="openDialog">已选{{selectItemList.length}}个分类</span>
            <span class="select-true" @click="editGroupList">确认选择</span>
            <span class="select-cancle" @click="cancelSelect">取消</span>

          </footer>
        </div>
      </li>
      <!-- 确认组件 -->
      <li class="shop-group-distri-layout li" v-if="!selectStatus">
        <p><span class="distri-layout-mark"></span><span class="distri-layout-title">商圈分类组件({{selectItemList.length}})个</span><span
            class="distri-layout-tip"></span></p>
        <p class="split-line"></p>
        <div class="shop-group-distri-select">
          <p class="distri-select-title">可拖动图标调整排序</p>
          <transition-group name="flip-list" class="distri-select-all">
            <div class="distri-select-item" v-for="(item,index) in selectItemList" :key='index+"dd"' draggable="true"
              @dragstart="dragstart(item)" @dragenter="dragenter(item,'selectItemList')" @dragend="dragend(item,'selectItemList')">
              <div>
                <img :src="item.icon" alt="">
                <img src="@/assets/img/common/closeIcons.png" alt="" class="selcet-icon" @click="deleteItem(index,'selectItemList')">
                <p>{{item.name}}</p>
              </div>
            </div>
          </transition-group>
          <footer class="footer">
            <PrevReclick>
            <span class="select-true" @click="saveSecondEdit">保存</span>
            </PrevReclick>
            <span class="select-cancle" @click="againSelect">重新选择</span>
          </footer>
        </div>
      </li>
      <li class="shop-group-distri-result li">
        <div class="left-swiper" @click="turnPage('-')">
          <img src="@/assets/img/common/lefttSwiper.png" alt="" style="padding-left:10px;">
          <p>点击左滑</p>
        </div>
        <div class="right-swiper" @click="turnPage('+')">
          <img src="@/assets/img/common/rightSwiper.png" alt="" style="padding-left:10px;">
          <p>点击右滑</p>
        </div>
        <p><span class="distri-layout-mark"></span><span class="distri-layout-title">商圈效果展示</span></p>
        <p class="split-line"></p>
        <div class="bg-example">
          <ul class="example-list">
            <li v-for="(item,index) in sortSelectItemList" :key='index'>
              <img :src="item.icon" alt="" style="width:40px;">
              <p class="ove">{{item.name}}</p>
            </li>
          </ul>
        </div>
      </li>
    </ul>
    <!-- 组件弹窗 -->
    <el-dialog :title="`已选图标${selectItemListEdit.length}个`" :visible.sync="dialogVisible" width="60%"   @close="cancleEdit">
       <li class="shop-group-distri-layout li" >
        <div class="shop-group-distri-select">
          <p class="distri-select-title">可拖动图标调整排序</p>
          <transition-group name="flip-list" class="distri-select-all">
            <div class="distri-select-item" v-for="(item,index) in selectItemListEdit" :key='index+"dd"' draggable="true"
              @dragstart="dragstart(item)" @dragenter="dragenter(item,'selectItemListEdit')" @dragend="dragend(item,'selectItemListEdit')">
              <div>
                <img :src="item.icon" alt="">
                <img src="@/assets/img/common/closeIcons.png" alt="" class="selcet-icon" @click="deleteItem(index,'selectItemListEdit')">
                <p>{{item.name}}</p>
              </div>
            </div>
          </transition-group>
          <footer class="footer">
            <PrevReclick>
            <span class="select-true" @click="editSelctList">确认</span>
            </PrevReclick>
            <span class="select-cancle" @click="cancleEdit">取消</span>
          </footer>
        </div>
      </li>
    </el-dialog>
  </div>
</template>

<script>
import noSelectIcon from '@/assets/img/common/noSelectIcon.png';
import SelectIcon from '@/assets/img/common/selectIcon.png';
import { $sg_getMchGroupCatSelect, $sg_mchGroupCatAgentSelect, $sp_addEditGroupCat } from '@/api/shopGroups';

const arr = [];
// eslint-disable-next-line no-plusplus
for (let i = 0; i < 30; i++) {
  arr.push({ name: '休闲娱乐', id: i });
}

export default {
  components: {},
  data() {
    return {
      noSelectIcon,
      SelectIcon,
      groupAllList: [],
      selectItemList: [],
      sortSelectNum: null,
      page: 0,
      selectStatus: false,
      sortNum: 0,
      oldSort: '',
      newSort: '',
      dialogVisible: false,
      selectItemListEdit: [],
    };
  },
  computed: {
    sortSelectItemList() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.sortSelectNum = this.selectItemList;
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.selectItemList.slice(0 + 8 * this.page, 8 + 8 * this.page);
    },

  },
  watch: {

  },

  created() {

  },
  mounted() {
    this.getMchGroupCatSelect();
    // this.mchGroupCatAgentSelect();
  },
  // 方法集合
  methods: {
    // 拖拽的二次保存
    saveSecondEdit() {
      this.$confirm('选择图标后，您名下的商圈用户端即刻展示，并且可以添加对应的商圈团购。', '确认选择图标', {
        confirmButtonText: '确定',
        cancelButtonText: '放弃',
        type: 'warning',
      }).then(() => {
        // eslint-disable-next-line no-shadow
        const arr = [];
        this.selectItemList.forEach((item) => {
          arr.push(item.id);
        });
        if (!arr.length) {
          this.$message.error('请选择商圈分类');
          return;
        }
        $sp_addEditGroupCat({ type: 'GROUP_CATEGORY', val: arr.join(',') }).then((res) => {
          console.log(res);
          // this.selectStatus = true;
          this.getMchGroupCatSelect();
        });
      }).catch(() => {
        
      });
    },
    // 重新选择
    againSelect() {
      this.selectItemList = JSON.parse(JSON.stringify(this.orginSelectItemList));
      this.selectStatus = true;
    },
    // 弹窗内编辑选中分类 
    editSelctList() {
      this.selectItemList = JSON.parse(JSON.stringify(this.selectItemListEdit));
      this.dialogVisible = false;
      // eslint-disable-next-line no-shadow
      const arr = [];
      this.selectItemList.forEach((item) => {
        arr.push(item.id);
      });
      
      this.groupAllList.forEach((item) => {
        if (arr.includes(item.id)) {
          item.selected = true;
          console.log(item.id, 'eee333');
        }
      });
      this.groupAllList.push();
      this.$message.success('操作成功');
    },
    // 取消选择
    cancelSelect() {
      this.selectItemList = [];
      this.groupAllList.forEach((item) => {
        item.selected = false;
      });
    },
    // 弹窗取消编辑
    cancleEdit() {
      this.dialogVisible = false;
     
      const selectArr = [];
      this.selectItemList.forEach((item) => {
        selectArr.push(item.id);
      });
      this.updateGroupList(selectArr);
    },
    // 团购基本分类
    async getMchGroupCatSelect() {
      await $sg_getMchGroupCatSelect().then((res) => {
        this.groupAllList = res;
      });
      this.mchGroupCatAgentSelect();
    },
    // 代理商选择商户 $sg_mchGroupCatAgentSelect
    mchGroupCatAgentSelect() {
      $sg_mchGroupCatAgentSelect().then((res) => {
        if (res && res.val) {
          // eslint-disable-next-line no-shadow
          const arr = res.val.split(',');
          this.selectItemList = [];
          this.groupAllList.forEach((item) => {
            if (arr.indexOf(`${item.id}`) !== -1) {
              item.selected = true;
              item.sortNum = arr.indexOf(`${item.id}`);
              this.selectItemList.push(item);
            }
          });
          this.groupAllList.push();
          this.selectStatus = false;
          const arrs = JSON.parse(JSON.stringify(this.selectItemList));
          this.selectItemList = arrs.sort((a, b) => a.sortNum - b.sortNum); 
          this.orginSelectItemList = JSON.parse(JSON.stringify(this.selectItemList));
        } else {
          this.selectStatus = true;
        }
      });
    },
   
    // 打开弹窗
    openDialog() {
      this.dialogVisible = true;
      this.selectItemListEdit = JSON.parse(JSON.stringify(this.selectItemList));
      this.updateGroupList(this.selectItemListEdit);
    },
    // 选中分类的处理
    selectType(item, index) {
      if (!item.selected) {
        if (this.selectItemList.length >= 20) {
          this.$message.error('至多可选择20个图标');
          return;
        }
        item.selected = true;
        item.index = index;
        // eslint-disable-next-line no-plusplus
        this.sortNum++;
        item.sortNum = this.sortNum;
        this.selectItemList.push(item);
        this.groupAllList.push();
      } else {
        item.selected = false;
        let key;
        this.selectItemList.forEach((op, indexs) => {
          if (op.index === item.index) {
            key = indexs;
          }
        });
        this.selectItemList.splice(key, 1);
        this.$nextTick(() => {
          this.groupAllList.push();
        });
      }
    },
    turnPage(type) {
      if (type === '+') {
        const maxNum = Math.floor(this.sortSelectNum.length / 8.01);
        console.log(this.sortSelectItemList, maxNum, this.page);
        if (this.page < maxNum) { this.page += 1; }
      } else if (this.page === 0) {
        this.page = 0;
      } else {
        this.page -= 1;
      }
    },
    // 编辑排序 
    editGroupList() {
      this.$confirm('选择图标后，您名下的商圈用户端即刻展示，并且可以添加对应的商圈团购。', '确认选择图标', {
        confirmButtonText: '确定',
        cancelButtonText: '放弃',
        type: 'warning',
      }).then(() => {
        // eslint-disable-next-line no-shadow
        const arr = [];
        this.selectItemList.forEach((item) => {
          arr.push(item.id);
        });
        if (!arr.length) {
          this.$message.error('请选择商圈分类');
          return;
        }
        $sp_addEditGroupCat({ type: 'GROUP_CATEGORY', val: arr.join(',') }).then((res) => {
          console.log(res);
          this.selectStatus = false;
          this.orginSelectItemList = JSON.parse(JSON.stringify(this.selectItemList));
        });
      }).catch(() => {
        
      });
    },
    // 拖动api
    // 开始拖动
    dragstart(val) {
      this.oldSort = val;
      console.log(val, 'start');
    },
    // 拖动过程中
    dragenter(val) {
      // 此时的val为被拖动元素想要放置的位置的元素
      this.newSort = val;
      console.log(val, 'ing');
    },
    // 拖动结束
    dragend(val, type) {
      console.log(val);
      if (this.oldSort === this.newSort) {
        this.oldSort = '';
        return;
      }
      console.log(val, 'ending');
      const oldIndex = this[type].indexOf(this.oldSort);
      const newIndex = this[type].indexOf(this.newSort);
      console.log(newIndex, '333');
      const newItems = [...this[type]];
      // 删除老的节点
      newItems.splice(oldIndex, 1);
      // 增加新的节点
      newItems.splice(newIndex, 0, this.oldSort);
      this[type] = [...newItems];
      this[type].push();
      this.oldSort = '';
    },
    // 删除所选图标
    deleteItem(index, type) {
      console.log(this[type], index);
      this[type].splice(index, 1);
      const selectArr = [];
      this[type].forEach((item) => {
        selectArr.push(item.id);
      });
      if (type === 'selectItemList') {
        this.updateGroupList(selectArr);
      }
    },
    // eslint-disable-next-line no-shadow
    updateGroupList(arr) {
      this.groupAllList.forEach((item) => {
        if (arr.includes(item.id)) {
          item.selected = true;
        } else {
          item.selected = false;
        }
      });
    },
  },
};
</script>
<style lang='scss' >
.shop-group-distri {
  padding: 20px 20px 0 20px;
  .el-badge__content{
       background-color: #feb300;
  }
  .flip-list-move {
    transition: transform 0.3s ease-in;
  }

  .item-active {
    background-color: rgba(0, 0, 0, 0.4);
  }
  .shop-group-distri-content {
    display: flex;
    justify-content: space-between;
    flex-flow: wrap;
    .li {
      // min-height: 600px;
      max-height: 1400px;

      background: white;
    }
 
    .shop-group-distri-result {
      width: 520px;
      margin-left: 20px;
      padding: 20px 0 20px 20px;
      position: relative;
      .left-swiper {
        cursor: pointer;
        position: absolute;
        top: 290px;
        text-align: left;
        color: #ef3f46;
      }
      .right-swiper {
        cursor: pointer;
        position: absolute;
        top: 290px;
        right: 10px;
        color: #ef3f46;
      }
      .distri-layout-mark {
        width: 3px;
        display: inline-block;
        height: 19px;
        background: #ef3f46;
        opacity: 1;
      }
      .distri-layout-title {
        padding: 0 10px;
        vertical-align: 4px;
        font-size: 16px;
        color: #4d4d4d;
      }
      .distri-layout-tip {
        color: #ef3f46;
        font-size: 12px;
        vertical-align: 4px;
      }
      .split-line {
        height: 0px;
        border: 1px solid #e4e4e4;
        opacity: 1;
        margin: 10px 0;
      }
      .bg-example {
        width: 75%;
        margin: 0 auto;
        height: 730px;
        background: url("../../assets/img/common/shopGroupEx1.png") no-repeat;
        position: relative;
        .example-list {
          position: absolute;
          padding: 10px;
          width: 90%;
          // height: 172px;
          top: 149px;
          left: 15px;
          display: flex;
          // justify-content: space-around;
          flex-flow: wrap;
          li {
            width: 65px;
            height: 65px;
            border: 1px solid #efefef;
            opacity: 1;
            border-radius: 4px;
            font-size: 12px;
            margin-bottom: 5px;
            margin-right: 10px;
            text-align: center;
          }
        }
      }
    }
  }
     .shop-group-distri-layout {
      flex: 1;
      padding: 20px;
      .distri-layout-mark {
        width: 3px;
        display: inline-block;
        height: 19px;
        background: #ef3f46;
        opacity: 1;
      }
      .distri-layout-title {
        padding: 0 10px;
        vertical-align: 4px;
        font-size: 16px;
        color: #4d4d4d;
      }
      .distri-layout-tip {
        color: #ef3f46;
        font-size: 12px;
        vertical-align: 4px;
      }
      .split-line {
        height: 0px;
        border: 1px solid #e4e4e4;
        opacity: 1;
        margin: 10px 0;
      }
      .shop-group-distri-select {
        // height: 802px;
        background: #f8f7f7;
        border: 1px solid #ececec;
        opacity: 1;
        border-radius: 6px;
        .distri-select-title {
          padding-left: 20px;
          font-size: 16px;
          line-height: 40px;
        }
        .distri-select-all {
          max-height: 500px;
          overflow-y: auto;
          background: #ffffff;
          border: 1px solid #ececec;
          opacity: 1;
          overflow-y: auto;
          margin: 10px 20px 20px 20px;
          border-radius: 5px;
          padding: 20px 20px 20px 60px;
          display: flex;
          // justify-content:space-around;
          flex-flow: wrap;
          .distri-select-item {
            width: 128px;
            height: 100px;
            border: 1px solid #efefef;
            opacity: 1;
            border-radius: 8px;
            padding: 10px 0 18px 0;
            text-align: center;
            cursor: pointer;
            position: relative;
            margin-right: 35px;
            margin-bottom: 20px;
            .selcet-icon {
              position: absolute;
            }
          }
        }
        footer {
          margin: 20px;
          height: 100px;
          padding-left: 50px;
          background: white;
          cursor: pointer;
          .item {
            display: inline-block;
            margin-top: 20px;
            margin-right: 40px;
          }
          .select-cancle {
            width: 110px;
            height: 32px;
            background: #ffffff;
            border: 1px solid #ececec;
            opacity: 1;
            border-radius: 4px;
            text-align: center;
            display: inline-block;
            line-height: 32px;
            margin-left: 50px;
            margin-right: 40px;
          }
          .select-true {
            width: 110px;
            height: 32px;
            background: #ef3f46;
            opacity: 1;
            border-radius: 4px;
            text-align: center;
            display: inline-block;
            line-height: 32px;
            color: white;
            margin-left: 50px;
          }
        }
        .footer {
          margin: 20px;
          height: 40px;
          padding: 20px;
          background: white;
          cursor: pointer;
          .select-cancle {
            width: 110px;
            height: 32px;
            background: #ffffff;
            border: 1px solid #ececec;
            opacity: 1;
            border-radius: 4px;
            text-align: center;
            display: inline-block;
            line-height: 32px;
            margin-left: 5px;
          }
          .select-true {
            width: 110px;
            height: 32px;
            background: #ef3f46;
            opacity: 1;
            border-radius: 4px;
            text-align: center;
            display: inline-block;
            line-height: 32px;
            color: white;
            margin-right: 40px;
          }
        }
      }
    }
}
</style>

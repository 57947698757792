import api from './api';

export const $sg_adversitBanlance = (params) => api.get('/j/agent/WxLocaladAdShop/getShopBalance', params);
// 广告开户状态 /j/WxLocaladAdShop/checkUploadQualificationState
export const $sg_checkUploadQualificationState = (params) => api.get('/j/agent/WxLocaladAdShop/checkUploadQualificationState', params);

// 行业选择 /j/WxLocaladAdShop/listIndustryAndCode
export const $sg_listIndustryAndCode = (params) => api.get('/j/agent/WxLocaladAdShop/listIndustryAndCode', params);
// 上传资料  /j/WxLocaladAdShop/checkUploadQualificationState
export const $sg_uploadDetail = (params) => api.post('/j/agent/WxLocaladAdShop/uploadQualificationState', params);
// 广告列表 /j/WxLocaladAd/pageShopAd
export const $sg_pageShopAd = (params) => api.get('/j/agent/WxLocaladAd/pageShopAd', params);
// 广告详情接口 /j/WxLocaladAdData/getAdDataByType
export const $sg_getAdDataByType = (params) => api.get('/j/agent/WxLocaladAdData/getAdDataByType', params);
// 广告新增手机号 /j/WxLocaladAdPhone/pageShopAdPhone
export const $sg_pageShopAdPhone = (params) => api.get('/j/agent/WxLocaladAdPhone/pageShopAdPhone', params);
// 创建广告
export const $sp_addAd = (params) => api.postNoMessage('/j/agent/WxLocaladAd/addAd', params);
// 创建广告回显
export const $sg_findVoById = (params) => api.get(`/j/agent/WxLocaladAd/findVoById?adId=${params}`);
// 新增广告之后更新投放类型
export const $sp_updateShowType = (params) => api.postNoMessage('/j/agent/WxLocaladAd/updateShowType', params);
// 判断广告是否支付
export const $sg_hasPay = (params) => api.get(`/j/agent/WxLocaladAd/hasPay?adId=${params}`);
// 商圈 /tradingAreaInfo/page 192.168.2.121:9600
export const $sp_tradingAreaInfo = (params) => api.get('/j/agent/tradingAreaInfo/page', params);
// 删除商圈 /tradingAreaInfo/delete/{id}
export const $sp_deletetradingAreaInfo = (id) => api.post(`/j/agent/tradingAreaInfo/delete/${id}`);
// 新增 /tradingAreaInfo/add
export const $sp_addradingAreaInfo = (params) => api.post('/j/agent/tradingAreaInfo/add', params);
// 商圈具体商户 /j/tradingAreaShop/list /j/tradingAreaShop/list
export const $sp_tradingAreaShopList = (params) => api.get('/j/agent/tradingAreaShop/list', params);
// 单个商圈商户 /tradingAreaInfo/get/{id}
export const $sp_shopItem = (id) => api.get(`/j/tradingAreaInfo/agent/get/${id}`);
// 新增商圈商户 tradingAreaShop/add
export const $sp_tradingAreaShopAdd = (params) => api.post('/j/agent/tradingAreaShop/add', params);
// 删除商户 /j/tradingAreaShop/delete
export const $sp_tradingAreaShopDelete = (params) => api.post('/j/agent/tradingAreaShop/delete', params);
// 保存引流图片 /tradingAreaInfo/add/qrCode
export const $sp_tradingAreaInfoqrCode = (params) => api.post('/j/agent/tradingAreaInfo/add/qrCode', params);
export const $sp_addradingAreaInfoUpdate = (params) => api.post('/j/agent/tradingAreaInfo/update', params);
// 商户商圈继续搜索
export const $getShopInfoAndProfit = (params) => api.get('/j/agent/tradingAreaShop/getShopInfoAndProfit', params);
export const $sg_findVoByShopId = (param) => api.get('/j/agent/WxLocaladAdShop/findVoByShopId', param);

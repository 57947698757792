<template>
  <div class="business-reviewed-page">
    <div class="searchForm">
      <el-form ref="form" :model="form" :inline="true" label-position="left" class="form">
        <el-form-item label="商户号" label-width="60px">
          <el-input type="number" class="inputStyle" v-model="form.shopId"></el-input>
        </el-form-item>
        <el-form-item label="商户名" label-width="60px">
          <el-input type="text" class="inputStyle" v-model="form.shopName" maxlength="20"></el-input>
        </el-form-item>
        <el-form-item label="商户电话" label-width="80px">
          <el-input type="number" class="inputStyle" v-model="form.tel"></el-input>
        </el-form-item>
        <el-form-item label="" label-width="60px">
          <el-button type="primary" class="sure" @click="screen">筛选</el-button>
          <el-button type="info" class="sure" @click="clear">清空</el-button>
        </el-form-item>
        <el-form-item label="" label-width="60px">
          <el-button type="primary" class="sure" @click="addShop">新增商圈</el-button>
        </el-form-item>
      </el-form>
    </div>
       <p class="group-shop-warn">注：筛选只能筛选已添加商圈的商户</p>
    <el-table :data="tableData" style="width: 100%" border>
      <el-table-column prop="id" label="序列号" align="center"></el-table-column>
      <el-table-column prop="tradingAreaName" label="商圈名字" width="200"></el-table-column>
      <el-table-column prop="contactTel" label="商圈商户" align="center">
        <template slot-scope="scope">
          <span style="color:#F63D2A;cursor: pointer;" @click="shopDetail(scope.row)">详情</span>
        </template>
      </el-table-column>

      <el-table-column prop="deduction" label="删除商圈" align="center">
        <template slot-scope="scope">
          <span style="color:#F63D2A;cursor: pointer;" @click="addDel(scope.row)">编辑</span>
        </template>
      </el-table-column>
      <el-table-column prop="profit" label="微信群" align="center"><template slot-scope="scope">
          <div>
        <span style="color:#F63D2A;cursor: pointer;padding-right:10px;" >{{scope.row.tradingAreaQrCode?'已设置':'未设置'}}</span>
        <el-button type="primary"   size="mini" @click="wxEdit(scope.row)">{{scope.row.tradingAreaQrCode?'修改':'配置'}}</el-button>
          </div>

        </template></el-table-column>
      <el-table-column prop="shopPattern" label="分享商圈小程序" align="center">
        <template slot-scope="scope">
          <span style="color:#F63D2A;cursor: pointer;" @click="openCodeImg(scope.row)">详情</span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination class="mt10" background :page-count="totalPage" :current-page.sync="currentPage" @current-change="pageChange">
    </el-pagination>
    <!-- 新增编辑商圈 -->
    <Dialog ref="groupList">
      <div style="margin-top:20px">
        <el-input v-model="addShopName" placeholder="" size="normal" clearable></el-input>
      </div>
      <div style="margin-top:30px">
        <PrevReclick>
        <p @click="addShopSure" class="unploadButtons pointer">{{shopGroupState=='edit'?'确认编辑':'确认新增'}}</p>
        </PrevReclick>
        <p @click="open()" class="unploadButtons pointer" style="background:#44444440" v-if="shopGroupState=='edit'">删除商圈</p>
      </div>
    </Dialog>
    <!-- 商圈商户 -->
    <el-dialog :title="shopName" :visible.sync="shopVisible" width="1100px">
       <h3 style="text-align:center;color:#f22424;font-size:20px">{{tradingAreaName}}商圈</h3>
      <el-table :data="gridData" border>
        <el-table-column property="shopId" label="商户号" width="120"></el-table-column>
         <el-table-column prop="shopName" label="商户名" width="100"></el-table-column>
        <el-table-column property="contactTel" label="商户联系方式" width="160"></el-table-column>
        <el-table-column property="createTime" label="商户添加时间" width="160">
           <template slot-scope="scope">
          <span>{{ $formatDate(scope.row.createTime, 'yyyy-MM-dd HH:mm') }}</span>
        </template>
        </el-table-column>
        <el-table-column property="expireTime" label="到期时间">
          <template slot-scope="scope">
          <span>{{ $formatDate(scope.row.expireTime, 'yyyy-MM-dd HH:mm') }}</span>
        </template>
        </el-table-column>
        <el-table-column property="deduction" label="抽佣比例" width="80"></el-table-column>
        <el-table-column property="profit" label="我的分润" width="80"></el-table-column>
        <el-table-column property="state" label="状态">
          <template slot-scope="scope">
            <span>{{stateList[scope.row.state]}}</span>
          </template>
        </el-table-column>
        <el-table-column property="address" label="编辑" align="center" width="80">
          <template slot-scope="scope">
            <span style="color:#F63D2A;cursor: pointer;" @click="deleteShop(scope.row)">删除</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination class="mt10" background :page-count="totalPages" :current-page.sync="currentPages" @current-change="pageChanges">
      </el-pagination>
      <div class="dialog-footer">
         <PrevReclick>
        <el-button type="primary" class="sure" @click="getShopList">新增商户</el-button>
         </PrevReclick>
        <el-button type="info" class="sure" @click="shopVisible=false">关闭</el-button>
      </div>

      <!-- 商户总列表 -->

    </el-dialog>
    <el-dialog title="" :visible.sync="buinessVisible" width="1100px" @close="buinessVisible=false">
      <div>
         <el-form ref="form" :model="form" :inline="true" label-position="left" class="form">

        <el-form-item label="商户名" label-width="60px">
          <el-input type="text" class="inputStyle" v-model="fliterForm.shopName" maxlength="20"></el-input>
        </el-form-item>
        <el-form-item label="商户电话" label-width="80px">
          <el-input type="number" class="inputStyle" v-model="fliterForm.contactTel"></el-input>
        </el-form-item>
        <el-form-item label="" label-width="60px">
          <el-button type="primary" class="sure" @click="fliterBuinessData">筛选</el-button>
          <el-button type="info" class="sure" @click="clearBuinessData">清空</el-button>
        </el-form-item>

      </el-form>
        <el-table :data="businessData" border @selection-change="handleSelectionChange" ref="multipleTable" tooltip-effect="dark">
          <el-table-column type="selection" width="55">
          </el-table-column>
          <el-table-column prop="shopCode" label="商户号"></el-table-column>
          <el-table-column prop="shopName" label="商户名" width="100"></el-table-column>
          <el-table-column prop="contactTel" label="商户联系方式"></el-table-column>
          <el-table-column prop="createTime" label="添加商户时间" width="150">
            <template slot-scope="scope">
              <span>{{ $formatDate(scope.row.createTime, 'yyyy-MM-dd HH:mm') }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="expireTime" label="到期时间" width="150">
            <template slot-scope="scope">
              <span>{{ $formatDate(scope.row.expireTime, 'yyyy-MM-dd HH:mm') }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="deduction" label="抽佣比例" width="80"></el-table-column>

          <el-table-column prop="profit" label="我的分润" width="120"></el-table-column>
          <el-table-column prop="state" label="状态">
            <template slot-scope="scope">
              <span :class="'status'+scope.row.state">{{ businessStatusList[+scope.row.state].label }}</span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination class="mt10" background :page-count="totalPageBinuess" :current-page.sync="currentPageBinuess"
          @current-change="pageChangeBinuess">
        </el-pagination>
        <div class="dialog-footer">
           <PrevReclick>
          <el-button type="primary" class="sure" @click="addShoplist">确认添加</el-button>
           </PrevReclick>
          <el-button type="info" class="sure" @click="buinessVisible=false">关闭</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- // 引流二维码配置 -->
    <el-dialog :visible.sync="centerDialogVisible" width="500px" class="dialong-style" center>
      <p>
        <span class="remark-line"></span>
        <span class="remark-title">引流二维码设置</span>
      </p>
      <header style="padding: 10px 0">
      </header>
      <aside>
        <p style="padding-bottom: 5px">二维码设置</p>
        <div class="iblock">
          <img class="pointer team_img" title="点击下方按钮添加图片" id="teamImage" width="150" height="150"
            :src="codeform.secondQrUrl ? codeform.secondQrUrl : defaultImg" />
          <el-upload class=" " action="#" :show-file-list="false" :http-request="img_head_upload">
            <p class="unploadButton">选择文件</p>
          </el-upload>
        </div>
       <p class="unploadButton pointer" @click="lookeExplore">示意图</p>
      </aside>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveBillboard">保存</el-button>
        <el-button @click="centerDialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <imgDialog ref="imgDialog"/>
    <!-- 小程序配置 -->
     <el-dialog :visible.sync="codeVisible" width="500px" class="code-img" center>
          <div class="img-box">
            <img :src="codeImgUrl" alt="" >
          </div>
          <p class="code-img-title">手机分享商圈</p>
          <p class="unploadButton pointer" @click="closeCode">关闭</p>
    </el-dialog>
    <!-- 查无商户的弹窗 -->
     <el-dialog :visible.sync="noShopVisible" width="500px" class="code-img" center>
          <div class="img-box"  style="border:none;">
            <img src="@/assets/img/warning.png" alt="" >
          </div>
          <p class="code-img-title">未查询到商户</p>
          <p class="unploadButton pointer" @click="noShopVisible=false">关闭</p>
    </el-dialog>
    <!-- 查到了商户的返回弹窗 -->
      <el-dialog  :visible.sync="secondVisible" width="1100px">
       <h3 style="text-align:center;color:#f22424;font-size:20px">此商户未加入商圈</h3>
      <el-table :data="secondShopData" border max-height="250" style="min-height:250px">
        <el-table-column property="shopId" label="商户号" width="120"></el-table-column>
         <el-table-column property="shopName" label="商户名" width="160"></el-table-column>
        <el-table-column property="contactTel" label="商户联系方式" width="160"></el-table-column>
        <el-table-column property="createTime" label="商户添加时间" width="160">
           <template slot-scope="scope">
          <span>{{ $formatDate(scope.row.createTime, 'yyyy-MM-dd HH:mm') }}</span>
        </template>
        </el-table-column>
        <el-table-column property="expireTime" label="到期时间">
          <template slot-scope="scope">
          <span>{{ $formatDate(scope.row.expireTime, 'yyyy-MM-dd HH:mm') }}</span>
        </template>
        </el-table-column>
        <el-table-column property="deduction" label="抽佣比例"></el-table-column>
        <el-table-column property="profit" label="我的分润"></el-table-column>
        <el-table-column property="state" label="状态">
          <template slot-scope="scope">
            <span>{{stateList[scope.row.state]}}</span>
          </template>
        </el-table-column>
      </el-table>
       <p class="unploadButtons pointer" @click="secondVisible=false">关闭</p>
    </el-dialog>
  </div>
</template>

<script>
import Dialog from '@/components/common/dialog.vue';
import {
  $sp_tradingAreaInfo, $sp_deletetradingAreaInfo, $sp_addradingAreaInfo,
  $sp_tradingAreaShopList, $sp_tradingAreaShopAdd, $sp_tradingAreaShopDelete,
  $sp_tradingAreaInfoqrCode, $sp_addradingAreaInfoUpdate, $getShopInfoAndProfit,
} from '@/api/adversiting';
import { $sg_reviewedShopPage } from '@/api/business';
import { UploadImg } from '@/global/common';
import defaultImg from '@/assets/img/unploadHeadImg.png';
import { getQrcodeSrc } from '@/global/globalMethods';
import imgDialog from './components/imgDialog.vue';

const stateList = ['', '正常', '隐藏', '删除', '禁用'];
export default {
  name: 'reviewed',
  components: {
    Dialog, imgDialog,
  },
  data() {
    return {
      defaultImg,
      form: {
        num: '',
        name: '',
        phone: '',
        businessStatus: '',
        tagEnd: '',
      },
      shopName: '',
      addShopName: '',
      tableData: [],
      totalPage: 1,
      currentPage: 1,
      totalPages: 1,
      currentPages: 1,
      totalPageBinuess: 1,
      currentPageBinuess: 1,
      dialogs: {},
      shopVisible: false,
      buinessVisible: false,
      centerDialogVisible: false,
      codeVisible: false,
      noShopVisible: false,
      gridData: [],
      stateList,
      item: null,
      shopstatus: true,
      businessData: [],
      businessStatusList: [
        { value: '', label: '全部' },
        { value: 1, label: '正常' },
        { value: 2, label: '到期' },
      ],
      selectList: [],
      codeform: { secondQrUrl: '' },
      imgId: '',
      hash: '',
      codeImgUrl: '',
      fliterForm: { shopName: '', contactTel: '' },
      shopGroupState: null,
      editId: '',
      tradingAreaName: '',
      secondVisible: false,
      secondShopData: [],
    };
  },
  created() {
    this.getList();
  },
  mounted() { },
  methods: {
    // 编辑商圈
    addDel(item) {
      this.editId = item.id;
      this.shopGroupState = 'edit';
      this.addShopName = item.tradingAreaName || '';
      this.$refs.groupList.dialogs = {
        title2: '编辑商圈',
      };
      this.$refs.groupList.showDialog = true;
    },
    // 小程序分享
    openCodeImg(item) {
      this.hash = item.tradingAreaHash;
      this.codeVisible = true;
      const url = window.location.host;
      const arr = url.split('.');
      const codeNum = arr[0].indexOf('-');
      arr[0] = arr[0].slice(0, codeNum);
      const code = arr.join('.');
      this.codeImgUrl = getQrcodeSrc(`https://${code}/sh_group/${this.hash}`);
    },
    // 关闭
    closeCode() {
      this.codeVisible = false;
    },
    // 查看示意图
    lookeExplore() {
      this.$refs.imgDialog.imgVisible = true;
    },
    // 编辑微信引流
    wxEdit(item) {
      this.codeform.secondQrUrl = item.tradingAreaQrCode;
      this.centerDialogVisible = true;
      this.imgId = item.id;
    },
    // 上传图片
    async img_head_upload(file) {
      const uploadImg = new UploadImg({ file: file.file, type: 'logo' });
      const resp = await uploadImg.upload();
      this.codeform.secondQrUrl = resp;
    },
    // 保存引流二维码
    saveBillboard() {
      if (this.codeform.secondQrUrl) {
        $sp_tradingAreaInfoqrCode({ id: this.imgId, tradingAreaQrCode: this.codeform.secondQrUrl }).then(() => {
          this.centerDialogVisible = false;
          this.getList();
        });
      } else {
        this.$message.error('请上传引流二维码');
      }
    },
    getList() {
      const params = {
        pageCurrent: this.currentPage,
        pageSize: 15,
        shopId: this.form.shopId || null,
        shopName: this.form.shopName || null,
        tel: this.form.tel || null,
      };
      return new Promise((resolve) => {
        $sp_tradingAreaInfo(params).then((res) => {
          this.tableData = res.records || [];
          this.totalPage = res.pages;
          resolve(res.records);
        });
      });
    },
    open() {
      this.$confirm('删除商圈后,商圈将无法恢复,商圈数据将不进行保留', '删除商圈', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        $sp_deletetradingAreaInfo(this.editId).then(() => {
          this.getList();
          this.$refs.groupList.showDialog = false;
        });
      }).catch(() => {

      });
    },
    // 删除商户 踢出商户后,此商户将不在此商圈进行展示,如删除后,需要继续展示,请在商圈添加此商户 商圈踢出此商户
    deleteShop(item) {
      this.$confirm('踢出商户后,此商户将不在此商圈进行展示,如删除后,需要继续展示,请在商圈添加此商户', '商圈踢出此商户', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        $sp_tradingAreaShopDelete({ shopIds: [item.shopId], tradingAreaHash: this.item.tradingAreaHash }).then(() => {
          const param = { tradingAreaHash: this.item.tradingAreaHash, pageCurrent: this.currentPages, pageSize: 10 };
          $sp_tradingAreaShopList(param).then((data) => {
            this.gridData = data.records;
          });
        });
      }).catch(() => {

      });
    },
    pageChange() {
      this.getList();
    },
    async screen() {
      if (this.form.tel) {
        const phoneRegular = /^\d{11}$/;
        if (!phoneRegular.test(this.form.tel)) {
          this.$message.error('请输入正确电话号码');
          return;
        }
      }
      this.currentPage = 1;
      const data = await this.getList();
      if (data.length < 1) {
        $getShopInfoAndProfit({
          shopId: this.form.shopId || null,
          shopName: this.form.shopName || null,
          tel: this.form.tel || null,
        }).then((res) => {
          if (res) {
            this.secondShopData = [];
            this.secondShopData.push(res);
            this.secondVisible = true;
          } else {
            this.noShopVisible = true;
          }
        });
      }
    },
    clear() {
      this.form = {
        shopId: '',
        shopName: '',
        tel: '',
      };
      this.currentPage = 1;
      this.getList();
    },
    addShop() {
      this.addShopName = '';
      this.shopGroupState = '';
      this.$refs.groupList.dialogs = {
        title2: '新增商圈',
      };
      this.$refs.groupList.showDialog = true;
    },
    addShopcancle() {
      this.$refs.groupList.showDialog = false;
    },
    addShopSure() {
      if (this.addShopName) {
        if (this.shopGroupState === '') {
          $sp_addradingAreaInfo({ tradingAreaName: this.addShopName }).then(() => {
            this.getList();
            this.$refs.groupList.showDialog = false;
          });
        } else {
          $sp_addradingAreaInfoUpdate({ tradingAreaName: this.addShopName, id: this.editId }).then(() => {
            this.getList();
            this.$refs.groupList.showDialog = false;
          });
        }
      } else {
        this.$message.error('请输入商户名');
      }
    },
    // 商圈商户
    shopDetail(item) {
      this.item = item;
      this.tradingAreaName = item.tradingAreaName;
      this.shopVisible = true;
      console.log(item.id, '333');
      $sp_tradingAreaShopList({ tradingAreaHash: this.item.tradingAreaHash, pageCurrent: this.currentPages, pageSize: 10 }).then((res) => {
        this.gridData = res.records;
        this.totalPages = res.pages;
      });
    },
    pageChanges() {
      $sp_tradingAreaShopList({ tradingAreaHash: this.item.tradingAreaHash, pageCurrent: this.currentPages, pageSize: 10 }).then((res) => {
        this.gridData = res.records;
        this.totalPages = res.pages;
      });
    },
    // 商户列表逻辑
    shoplistAll(params = {}) {
      $sg_reviewedShopPage(params).then((res) => {
        this.businessData = res.records;
        this.totalPageBinuess = res.pages;
      });
    },
    // 新增商户
    getShopList() {
      this.buinessVisible = true;
      const params = {
        pageCurrent: this.currentPageBinuess,
        pageSize: 10,
      };
      this.shoplistAll(params);
    },
    pageChangeBinuess() {
      const params = {
        pageCurrent: this.currentPageBinuess,
        pageSize: 10,
        ...this.fliterForm,
      };
      this.shoplistAll(params);
    },
    // 搜索
    fliterBuinessData() {
      this.currentPageBinuess = 1;
      const params = {
        pageCurrent: 1,
        pageSize: 10,
        ...this.fliterForm,
      };
      this.shoplistAll(params);
    },
    clearBuinessData() {
      this.currentPageBinuess = 1;
      const params = {
        pageCurrent: 1,
        pageSize: 10,
      };
      this.fliterForm = { contactTel: '', shopName: '' };
      this.shoplistAll(params);
    },
    // table选择
    handleSelectionChange(item) {
      this.selectList = item;
    },
    // 单行选择

    addShoplist() {
      const shopIds = [];
      this.selectList.forEach((item) => {
        shopIds.push(item.shopId);
      });
      if (shopIds.length === 0) {
        this.$message.error('请先选择商户添加');
        return;
      }
      $sp_tradingAreaShopAdd({
        shopIds,
        tradingAreaHash: this.item.tradingAreaHash,
      }).then(() => {
        this.buinessVisible = false;
        $sp_tradingAreaShopList({ tradingAreaHash: this.item.tradingAreaHash, pageCurrent: 1, pageSize: 10 }).then((res) => {
          this.gridData = res.records;
          this.totalPages = res.pages;
        });
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/style/var.scss";
.business-reviewed-page {
  width: 100%;
  min-width: 1400px;
  height: 100%;
  overflow-y: auto;
  margin: 0;
  padding: 40px 20px;
  box-sizing: border-box;
  background-color: #fff;
}
.group-shop-warn{
  line-height: 40px;
   color:#ef3f46;
}
.code-img{
 .img-box{
   width: 200px;
   height:200px;
   border: 1px solid #444;
   border-radius: 200px;
   margin: 20px auto;
   overflow: hidden;
   img{
     width: 200px;
     height:200px;
   }
 }
 .code-img-title{
   margin-top: 20px;
   font-size: 20px;
   color: #444;
   font-size: 600;
   text-align: center;
 }
 .unploadButton {
    width: 150px;
    height: 32px;
    border: 1px solid #ef3f46;
    border-radius: 4px;
    height: 32px;
    background: #ef3f46;
    border-radius: 4px;
    color: #ffffff;
    line-height: 32px;
    text-align: center;
    margin: 20px auto;
  }
}
.unploadButtons {
    width: 150px;
    height: 32px;

    border-radius: 4px;
    height: 32px;
    background: #ef3f46;
    border-radius: 4px;
    color: #ffffff;
    line-height: 32px;
    text-align: center;
    margin: 20px auto;
  }
  .el-dialog__body{
    padding: 20px 20px ;
  }
.dialong-style {
  /deep/ .el-dialog__body {
    padding: 25px 50px 30px !important;
  }
  .remark-line {
    display: inline-block;
    height: 20px;
    width: 4px;
    border-radius: 4px;
    background: red;
  }
  .remark-title {
    display: inline-block;
    vertical-align: 3px;
    font-size: 18px;
    font-weight: bold;
    padding-left: 5px;
  }
  .unploadButton {
    width: 150px;
    height: 32px;
    border: 1px solid #ef3f46;
    border-radius: 4px;
    height: 32px;
    background: #ef3f46;
    border-radius: 4px;
    color: #ffffff;
    line-height: 32px;
    text-align: center;
    margin-top: 10px;
  }
}
.el-form--inline .el-form-item {
  margin-right: 50px;
}
.inputStyle {
  width: 180px;
}
.dialog-footer {
  display: flex;
  flex-flow: wrap;
  justify-content: space-around;
  width: 80%;
  margin: 20px auto;
}
.timeChoose {
  width: 110px;
  text-align: left;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #606266;
  line-height: 40px;
  padding: 0 12px 0 0;
  box-sizing: border-box;
}
.sure {
  margin-left: 30px;
}
.orderExplain {
  width: 100%;
  height: 40px;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.orderExplainTxt {
  font-size: 14px;
  font-weight: 400;
  color: #f22424;
}
.el-table {
  min-height: 580px;
  & > th {
    background-color: #ef3f46;
    color: #fff;
  }
}
.question {
  width: 14px;
  height: 14px;
  margin-left: 8px;
  cursor: pointer;
}
.status1 {
  color: #333;
}
.status2 {
  color: #f22424;
}
.dialog-page {
  .icon {
    width: 82px;
    height: 82px;
  }
  .title {
    font-size: 18px;
    font-weight: bold;
    line-height: 25px;
    color: #333333;
    text-align: center;
    margin-top: 23px;
  }
  .title2 {
    font-size: 20px;
    font-weight: bold;
    line-height: 28px;
    color: #333333;
    margin-top: 17px;
    text-align: center;
    .diagonal {
      width: 24.37px;
      height: 14.02px;
    }
  }

  .content {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #666666;
    text-align: center;
    margin-top: 12px;
    padding: 0 10px;
  }
  .content2 {
    padding: 0 14px;
    max-height: 450px;
    overflow-y: auto;
    .title {
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      color: #333333;
      margin: 12px 0;
      text-align: left;
    }
    .list {
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      color: #666666;
    }
  }
  .done-btn {
    width: 260px;
    height: 36px;
    line-height: 36px;
    background: #f22424;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    margin: 31px auto 0;
  }
  .gradients-btn.van-button {
    font-size: 32px !important;
    height: 80px !important;
    line-height: 80px !important;
  }
  .double-btn {
    margin: 28px 0px 0px 0px;
    // padding: 0 86px;
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    .left-btn {
      width: 210px;
      height: 80px;
      background: #ededed;
      opacity: 1;
      border-radius: 40px;
      font-size: 32px;
      color: #444444;
      text-align: center;
      line-height: 80px;
    }
    .right-btn {
      color: #ffffff;
      background: $--theme-color;
      width: 210px;
      height: 80px;
      opacity: 1;
      border-radius: 40px;
      font-size: 32px;

      text-align: center;
      line-height: 80px;
    }
  }
}
</style>
